import React, { useState } from "react";
import Navbar from './app/components/Navbar.js'
import ProductList from './app/components/ProductListCardsGrid.js'
import { Container, Row, Col } from 'react-grid-system';
import PersonaSelection from './app/components/PersonaSelection.js'
import IKEAIcon from './app/components/IKEAIcon.js'
import 'semantic-ui-css/semantic.min.css'

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      persona: ""
    };
  }
  handlePersona = (personaValue) => {
    this.setState({persona: personaValue});
}
  render() {

    
    return [
      
      <Container>
      <Row>
      <Col md={0.5}>
        <Navbar/>
      </Col>
      <Col md={1}>
      <IKEAIcon/>
       </Col>
      <Col justify="end" offset={{ md: 7 }}>
        <PersonaSelection onSelectPersona={this.handlePersona}/>
      </Col>
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProductList/>
        </Col>
      </Row>
      </Container>
    ];
  }
}
export default Application