import React from 'react'


import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/image/dist/style.css";
import "@ingka/aspect-ratio-box/dist/style.css";
import "@ingka/card/dist/style.css";
import "@ingka/focus/dist/style.css";
import Card from "@ingka/card";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/focus/dist/style.css";
import '@ingka/grid/dist/style.css';
import Image from "@ingka/image";
import Button from "@ingka/button";
import { Container, Row, Col } from 'react-grid-system';


  const PulseCXCard = {
    title: "PulseCX",
    label: "Customer Experience Insight",
    body: "PulseCX is a really cool tool that does things like insigts on stuff.",
    href: "https://pulsecx.ikea.com",
    imageProps: {
      src: "/img/PulseCX.jpg",
      //srcSet: "https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=xxxl 1400w,https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=xxl 950w,https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=xl 800w, https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=l 750w, https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=m 600w, https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=s 500w, https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=xs 400w, https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=xxs 300w, https://www.ikea.com/images/25/82/2582c41082e474e9e7f423219691be75.jpg?f=xxxs 240w",
      //sizes: "(min-width: 37.5em) 40vw, 90vw",
      //loading: "lazy"
    },
    compact: "true",
    emphasised: "true",
    backgroundColor: "#F5F5F5",
    ctaLabel: "Find out more",

  };
  const MarketPulseCard = {
    title: "Market Pulse",
    label: "Strategic and Market Insight",
    body: "Market Pulse is a really cool tool that does things like insigts on stuff.",
    href: "https://businessperformance.inter-ikea.com/inter-ikea/sense/app/ac8f3af5-aab5-4b6f-bfcb-03e7bf0ed474/sheet/a30b2258-c412-49ab-a327-61cc00e71292/state/analysis",
    imageProps: {
      src: "/img/MarketPulse.jpg",
      srcSet: ""},
    compact: "false",
    emphasised: "false",
    backgroundColor: "#F5F5F5",
    ctaLabel: "Find out more",

  };

  const BrandPulseCard = {
    title: "Brand Pulse",
    label: "Brand and Range Insight",
    body: "Brand Pulse is a really cool tool that does things like insigts on stuff.",
    href: "https://businessperformance.inter-ikea.com/inter-ikea/sense/app/ac8f3af5-aab5-4b6f-bfcb-03e7bf0ed474/sheet/a30b2258-c412-49ab-a327-61cc00e71292/state/analysis",
    imageProps: {
      src: "/img/BrandPulse.jpg",
      srcSet: ""},
    compact: "false",
    emphasised: "false",
    backgroundColor: "#F5F5F5",
    ctaLabel: "Find out more",

  };

  const HowPeopleLiveCard = {
    title: "How People Live",
    label: "Brand and Range Insight",
    body: "How People Live is a really cool tool that does things like insigts on stuff.",
    href: "https://businessperformance.inter-ikea.com/inter-ikea/sense/app/ac8f3af5-aab5-4b6f-bfcb-03e7bf0ed474/sheet/a30b2258-c412-49ab-a327-61cc00e71292/state/analysis",
    imageProps: {
      src: "/img/HowPeopleLive.jpg",
      srcSet: ""},
    compact: "true",
    emphasised: "false",
    backgroundColor: "#F5F5F5",
    ctaLabel: "Find out more",

  };
  const HowPeopleShopCard = {
    title: "How People Shop",
    label: "Customer Experience Insight",
    body: "How People Shop is a really cool tool that does things like insigts on stuff.",
    href: "https://businessperformance.inter-ikea.com/inter-ikea/sense/app/ac8f3af5-aab5-4b6f-bfcb-03e7bf0ed474/sheet/a30b2258-c412-49ab-a327-61cc00e71292/state/analysis",
    imageProps: {
      src: "/img/HowPeopleShop.jpg",
      srcSet: ""},
    compact: "false",
    emphasised: "false",
    backgroundColor: "#F5F5F5",
    ctaLabel: "Find out more",

  };
  const CompetitorsCompassCard = {
    title: "Competitors Compass",
    label: "Strategic and Market Insight",
    body: "Competitors Compass is a really cool tool that does things like insigts on stuff.",
    href: "https://businessperformance.inter-ikea.com/inter-ikea/sense/app/ac8f3af5-aab5-4b6f-bfcb-03e7bf0ed474/sheet/a30b2258-c412-49ab-a327-61cc00e71292/state/analysis",
    imageProps: {
      src: "/img/CompetitorCompass.jpg",
      srcSet: ""},
    compact: "false",
    emphasised: "false",
    backgroundColor: "#F5F5F5",
    ctaLabel: "Find out more",

  };
  const HowTheWorldChangesCard = {
    title: "How The World Changes",
    label: "How The World Changes",
    body: "How The World Changes is a really cool tool that does things like insigts on stuff.",
    href: "https://businessperformance.inter-ikea.com/inter-ikea/sense/app/ac8f3af5-aab5-4b6f-bfcb-03e7bf0ed474/sheet/a30b2258-c412-49ab-a327-61cc00e71292/state/analysis",
    imageProps: {
      src: "/img/HowTheWorldChanges.jpg",
      srcSet: ""},
    compact: "false",
    emphasised: "false",
    backgroundColor: "#F5F5F5",
    ctaLabel: "Find out more",

  };

  const IcmiCard = {
    href: "https://businessperformance.inter-ikea.com/inter-ikea/sense/app/ac8f3af5-aab5-4b6f-bfcb-03e7bf0ed474/sheet/a30b2258-c412-49ab-a327-61cc00e71292/state/analysis",
    sizes: '(min-height:500px) 500px',
    compact: "true",
    emphasised: "true",
    backgroundColor: "#F5F5F5",
    ctaLabel: "Find out more",
    imagePosition: "leading",
    imageProps: {
      src: "/img/PX001172.jpg",
      srcSet: ""},
  };
class ProductList extends React.Component {


      render() {

    
        return [
          // Docs: https://www.npmjs.com/package/react-grid-system
          <Container>
            <Row>
            <Col md={3}>
                <div style={{backgroundColor: "#F5F5F5",width: "auto",height: "100%",}} >
                <Image {...{src: "/img/PX001172.jpg",srcSet: "",}}  style={{backgroundColor: "#F5F5F5",width: "auto",height: "100%",}} />
                <Button   type="emphasised" text="Explore ICMI" style={{  position: "absolute",bottom: "21px",left: "40px"}} />
                </div>
              </Col>
              <Col md={3}>
                <Card {...PulseCXCard} />
              </Col>
              <Col md={3}>
              <Card {...MarketPulseCard} />
              </Col>
              <Col md={3}>
              <Card {...BrandPulseCard} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={3}>
                <Card {...HowPeopleLiveCard} />
              </Col>
              <Col md={3}>
              <Card {...HowPeopleShopCard} />
              </Col>
              <Col md={3}>
              <Card {...CompetitorsCompassCard} />
              </Col>
              <Col md={3}>
                <Card {...HowTheWorldChangesCard} />
              </Col>

            </Row>
            <br />
          </Container>
];
}
}


export default ProductList
