import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

const roleOptions = [
    {
      key: 'insights-specialist',
      text: 'Insights Specialist',
      value: 'insights-specialist',
      //image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: 'ingka-role1',
      text: 'INGKA Role',
      value: 'ingka-role1',
      //image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
  ]
  const styleRoleSelection = {
    display: "block",
    padding: "25px 10px",
    textDecoration: "none",
    verticalAlign: "text-bottom	",
    textAlign: "right",
    //border: "solid",
  };
  class PersonaSelection extends Component {

    constructor(props) {
      super(props);
      this.props.onSelectPersona(roleOptions[0].value);
    }  

  handlePersonaChange = (event, data) => {
    this.props.onSelectPersona(data.value);            
  }

  render() {

    return (
    <span style={styleRoleSelection}>
      I am an {' '}
      <Dropdown
        inline
        options={roleOptions}
        defaultValue={roleOptions[0].value}
        onChange={this.handlePersonaChange}
        valueField='persona' textField='persona'
      />
    </span>
    )
    }
}
export default PersonaSelection  