import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import Image from "@ingka/image"

function Navbar() {

    const [navbarOpen, setNavbarOpen] = useState(false)

    const links = [
      {
        id: 1,
        path: "/",
        text: "Home",
      }, 
      {
        id: 2,
        path: "/about",
        text: "About",
      }
    ]

    const styleMenuLinksItem = {
        display: "block",
        padding: "10px 100px",
        textDecoration: "none",
        color: "#ffdb00",
        fontWeight: "bold",
      };
      const styleMenuLinks = {
        display: "block",
        padding: "100px 0px",
        textDecoration: "none",
        fontWeight: "bold",
      };
      const styleNavBarIcon = {
        position: "relative",
        left: "2px",
        width: "50px",

      }; 
    const handleToggle = () => {
      setNavbarOpen(!navbarOpen)
    }
  
    const closeMenu = () => {
      setNavbarOpen(false)
    }
  
    return (
      <nav className="navBar">
        <button onClick={handleToggle} style={styleNavBarIcon}>
          {
            navbarOpen ? 
            <Image style={styleNavBarIcon} {... {src: "./icon/cross.svg"}} /> : 
            <Image style={styleNavBarIcon} {... {src: "./icon/mattress-firm.svg"}} /> 

          }
        </button>
        <ul className={`menuNav ${navbarOpen ? " showMenu": ""}`} style={styleMenuLinks}>
          <div style={{padding: "10px 110px"}}>
            <Image {... {src: "./ikea-logo.svg"}} />
          </div>
          {links.map((link) => {
            return (
              <li key={link.id}>
                <NavLink 
                  to={link.path} 
                  style={styleMenuLinksItem}
                  activeClassName="active-link"
                  onClick={() => closeMenu()}
                  exact
                >
                  {link.text}
                </NavLink>
              </li>
            )
          })}
        </ul>
      </nav>
    )  
  }
export default Navbar
